.navbar-item {
  color : #ffffff75;
}

.section_client {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url(./images/header.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; 
}

.section1 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%), url(./images/about-us.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover; 
}

@media screen and (min-width: 992px) {
  .section1{
    height : 800px;
  }
}

@media screen and (max-width: 992px) {
  .section1{
    height : 400px;
  }
}
.section2 {
  margin-right : 100px;
  margin-left : 100px
}